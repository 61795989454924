export const palette: any = {
  material: {
    type: "light",
    primary: {
      main: "#ED0000"
    },
    secondary: {
      main: "#7C7C7C"
    },
    background: {
      paper: "#fff",
      default: "#fafafa"
    }
  },
  generic: [
    "#B21015"
  ],
  statisticsDashboard: [0],
};
