import i18next from "i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const locales: any = {
  en: "d4f9d39ba86ea78c9f336dc3be67f239",
  fr: "d378e3f24d6296b834b9051815b9cf73",
  de: "e2f6cf79c14f989afea64be4363fa07d",
};

const options = {
  loadPath: (lngs: string[], namespace: string[]): string => {
    return `https://api.phraseapp.com/api/v2/projects/70ba287fe3c169083f9d9af00a2e0e92/locales/${
      locales[lngs[0]]
    }/download?file_format=simple_json&tags=${namespace[0]}`;
  },

  customHeaders: {
    "Cache-Control": "no-store",
    Authorization:
      "token 823bbaf5b167d37dd79cabdfe7b5a81746d4562dc9cc1ec851268c7a1d378629",
    "Content-Type": "application/json",
  },
};

declare global {
  interface InitOptionsExtended extends i18next.InitOptions {
    supportedLanguages: string[];
  }
}

i18next
  .use(LanguageDetector)
  .use(XHR)
  .use(initReactI18next)
  .init({
    load: "languageOnly",
    fallbackLng: "en",
    defaultNS: "frontend",
    ns: ["frontend"],
    backend: options,
    // Development only
    // supportedLanguages: ["en", "yo"],
    // whitelist: ["en", "yo"],
    supportedLanguages: ["en", "fr", "de"],
    whitelist: ["en", "fr", "de"],
    keySeparator: ":::",
    nsSeparator: "::",
  } as InitOptionsExtended);
